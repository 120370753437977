<template>
    <div class="case">
        <div class="banner">
            <Banner :type="'NEWS'" :height="500"></Banner>
        </div>
        <div class="news-detail">
            <h1>{{data.title}}</h1>
            <p>{{data.content}}</p>
        </div>
    </div>
</template>

<style lang="scss" src="./news.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import { useRoute } from "vue-router";
import Banner from "../../components/banner";

export default {
    setup(props) {

        let { proxy } = getCurrentInstance();
        let route = useRoute();

        const data = reactive({
            title:"",
            content:""
        });

        onMounted(() => {
            proxy.$axios.get("/new/get",{
                params:{
                    id:route.query.id
                }
            })
            .then((res)=>{
                data.title = res.data.title;
                data.content = res.data.content
            })
        });

        return {
            data
        };
    },
    components:{
        Banner
    }
};
</script>
